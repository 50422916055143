<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Address</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.addressDialog.open(property.address)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Address</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Line 1</th>
                  <td>{{ property.address.address1 }}</td>
                </tr>
                <tr>
                  <th>Line 2</th>
                  <td>{{ property.address.address2 }}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{{ property.address.city }}</td>
                </tr>
                <tr>
                  <th>County</th>
                  <td>{{ property.address.county }}</td>
                </tr>
                <tr>
                  <th>Postcode</th>
                  <td>{{ property.address.postcode }}</td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>{{ property.address.country }}</td>
                </tr>
                <tr>
                  <th>Lat</th>
                  <td>{{ property.address.lat }}</td>
                </tr>
                <tr>
                  <th>Long</th>
                  <td>{{ property.address.long }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Price History</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.priceDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Price</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Price</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="price in property.prices" :key="price.id">
                  <td>{{ price.formatted_price }}</td>
                  <td>{{ price.type }}</td>
                  <td>
                    <v-chip
                      label
                      small
                      color="success"
                      v-if="property.current_price.id === price.id"
                      >Current</v-chip
                    >
                    <v-chip label small v-else>Past</v-chip>
                  </td>
                  <td>{{ price.formatted_dates.created_at }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-5"
            >
              <div class="flex-grow-1">Sellers Details</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.customerDialog.open()"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Customer</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-melrose-customers-individual',
                        params: { customerId: property.customer.id },
                      }"
                      >{{ property.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ property.customer.email }}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{ property.customer.phone }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card outlined>
            <v-card-title
              class="d-flex justify-start align-center grey lighten-5"
            >
              <div class="flex-grow-1">Property Details</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.propertyDetailsDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Property's Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Type</th>
                  <td>{{ property.type }}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{{ property.status }}</td>
                </tr>
                <tr>
                  <th>Closing Date</th>
                  <td>
                    {{
                      property.closing_date
                        ? formatDate(property.closing_date)
                        : "N/A"
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Total Bedrooms</th>
                  <td>{{ property.total_bed_rooms }}</td>
                </tr>
                <tr>
                  <th>Total Reception Rooms</th>
                  <td>{{ property.total_reception_rooms }}</td>
                </tr>
                <tr>
                  <th>Total Bathrooms</th>
                  <td>{{ property.total_bath_rooms }}</td>
                </tr>
                <tr>
                  <th>Has Garden?</th>
                  <td>{{ property.has_garden ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Has Garage?</th>
                  <td>{{ property.has_garage ? "Yes" : "No" }}</td>
                </tr>
                <tr>
                  <th>Council Tax Band</th>
                  <td>{{ property.council_tax_band }}</td>
                </tr>
                <tr>
                  <th>EPC</th>
                  <td>{{ property.epc }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <price-dialog ref="priceDialog" />
    <address-dialog ref="addressDialog" />
    <customer-dialog ref="customerDialog" :property="property" />
    <property-details-dialog ref="propertyDetailsDialog" />
  </div>
</template>

<script>
import PriceDialog from "./components/PriceDialog.vue";
import AddressDialog from "./components/AddressDialog.vue";
import CustomerDialog from "./components/CustomerDialog.vue";
import PropertyDetailsDialog from "./components/PropertyDetailsDialog.vue";

export default {
  components: {
    PriceDialog,
    AddressDialog,
    CustomerDialog,
    PropertyDetailsDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
